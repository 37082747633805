import React, { useState } from 'react';
import Stars from './Stars';
import toast from 'react-hot-toast';
import './ComingSoon.css';

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

const ComingSoon: React.FC = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const tightloopToast = (
    title: string,
    desc: string,
    button: string,
  ) => {
    toast.custom((t) => (
      <div className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } max-w-md w-full bg-black shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-gray-800 toast-slide-up`}>
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <img src="/logo.svg" alt="" className="h-10 w-10" />
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-white">{title}</p>
              {desc && <p className="mt-1 text-sm text-gray-400">{desc}</p>}
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-700">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-gray-400 hover:text-gray-200 focus:outline-none"
          >
            {button}
          </button>
        </div>
      </div>
    ), {
      duration: 5000,
    });
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name) return;

    const zapierWebhookUrl = process.env.ZAPIER_WEBHOOK_URL || 'https://hooks.zapier.com/hooks/catch/19878333/26szv7c/';

    let response;
    try {
        // response = await fetch(`${API_BASE_URL}/api/waitlist`, {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({ email, name }),
        // });

        const timestamp = new Date(Date.now());

        response = await fetch(zapierWebhookUrl, {
          method: 'POST',
          body: JSON.stringify({ Email: email, Name: name, Timestamp: timestamp }),
        });

        if (response.ok) {
            tightloopToast(
              'Waitlist paused.',
              "We are no longer accepting new waitlist submissions.\nPlease stay tuned for public beta!",
              'Will do!'
            );
            // tightloopToast(
            //   'Thanks for joining',
            //   "We'll notify you when you're off the waitlist.",
            //   'Got it!'
            // );
        } else {
          throw new Error('Failed to join waitlist');
        }
      } catch (error) {
        console.error('Error joining waitlist:', error);
        // if (response && response.status === 400) {
        //   tightloopToast(
        //     'Already on waitlist',
        //     "We'll notify you when you're off the waitlist.",
        //     'Neat!'
        //   );
        // } else {
          tightloopToast(
            'Waitlist paused.',
            "We are no longer accepting new waitlist submissions.\nPlease stay tuned for public beta!",
            'Will do!'
          );
          // tightloopToast(
          //   'Unable to join waitlist at this time.',
          //   'Please try again later.',
          //   'Okay.'
          // );
        // }
      }

      resetForm();
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const resetForm = () => {
    setEmail('');
    setName('');
  };

  const noNameToast = () => {
    tightloopToast(
      'Waitlist paused.',
      "We are no longer accepting new waitlist submissions.\nPlease stay tuned for public beta!",
      'Will do!'
    );
    // tightloopToast(
    //   'Please enter your name to join the waitlist.',
    //   "We'd love to know who we're addressing!",
    //   'Will do.'
    // );
};

  const emailForm = () => (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col sm:flex-row max-w-md mx-auto rounded-lg overflow-hidden outline outline-1 outline-gray-700"
    >
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Your Email Address"
        className="flex-grow p-3 bg-gray-900 text-white placeholder-gray-500 focus:outline-none"
        required
      />
      <div className='bg-gray-900 p-2'>
        <button
          type="submit"
          className={`w-full sm:w-auto rounded-md bg-white text-black px-6 py-3 flex items-center bg-gray-100 hover:bg-white transition-colors justify-center ${
            isExpanded ? 'scale-0' : 'scale-100'
          }`}
          onClick={email ? toggleExpansion : () => {}}
        >
          Join Waitlist
          <img src="/logo.svg" alt="" className="ml-1 w-6 h-6" />
        </button>
      </div>
    </form>
  );

  const nameForm = () => (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col sm:flex-row max-w-md mx-auto rounded-lg overflow-hidden outline outline-1 outline-gray-700"
    >
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Your Name"
        className="flex-grow p-3 bg-gray-900 text-white placeholder-gray-500 focus:outline-none"
      />
      <div className='bg-gray-900 p-2'>
        <button
          type="submit"
          className={`w-full sm:w-auto rounded-md bg-white text-black px-6 py-3 flex items-center bg-gray-100 hover:bg-white transition-colors justify-center ${
            isExpanded ? 'scale-100' : 'scale-0'
          }`}
          onClick={name ? toggleExpansion : noNameToast}
        >
          Submit ✅
        </button>
      </div>
    </form>
  );

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 text-center bg-black text-white relative overflow-hidden">
      <Stars />
      <div className="absolute bottom-0 w-full h-1/3 bg-gradient-to-t from-purple-500 to-transparent opacity-30"></div>

      <div className="z-10">
        <div className="mb-8 inline-block px-3 py-1 text-sm bg-gray-900 rounded-full outline outline-1 outline-gray-700">
          now in private beta 💻
        </div>

        <h1 className="text-5xl sm:text-6xl font-thin tracking-widest mb-2">
          TIGHTLOOP
        </h1>
        <h2 className="text-3xl sm:text-4xl italic mb-4">

        </h2>

        <p className="mb-8 max-w-2xl mx-auto text-gray-500">
        The world's first IDE designed specifically for blockchain development.
        </p>

        <div className="relative">
          {isExpanded ? nameForm() : emailForm()}
        </div>

        <div className="mt-8 text-gray-700 text-sm">
            Copyright © 2024 Tightloop - All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
